import React, { useMemo } from 'react';
import { graphql, navigate } from 'gatsby';
import { Link } from "@Levi/components/common/link";
import ContentfulSectionContainer, {
  ContentfulSectionContainerTypes,
} from '../containers/contentfulSectionContainer';
import { PageProps } from './types';
import useAccommodationData, {
  AccommodationFieldsRaw,
} from '../../hooks/useAccommodationData';
import CardGridHeader from '../../components/common/cardgrid/CardGridHeader';
import CardGrid from '../../components/common/cardgrid/CardGrid';
import { CardType } from '../../components/common/card/Card';
import useContentfulTransformedCardGridData from '../../hooks/useContentfulTransformedCardGridData';
import Styled from './contentFulAccommodationPage.styled';
import Section from '../../components/section/Section';
import { SearchPageGrid } from '../../components/common/layout/Grid.styled';
import ArrowLink from '../../components/common/link/ArrowLink';
import useContentfulCallToAction, {
  LinkProps,
} from '../../hooks/useContentfulCallToAction';
import { TertiaryButton } from '../../components/common/button/Button';
import Icon from '../../components/common/icon/Icon';
import { Icons } from '../../types/icon-types';
import { Guests } from '../../components/common/list-filters/FilterGuestsForm';
import { DateRange, isValidDateRange } from '../../utils/date-utils';
import AccommodationSearchBar from '../../components/search/AccommodationSearchBar';
import { localize } from '../../localization/i18n';
import { useHeaderStoreAPI } from '../../components/header/headerStore';
import { encodeAsURIParams, encodeDateRangeParams } from './utils';
import { isDomAvailable } from '../../utils/env-utils';

const initialGuests: Guests = {
  adults: 0,
  children: 0,
};

type ContentfulAccommodationsPageProps = PageProps & {
  data: {
    iglus: {
      edges: Array<{ node: AccommodationFieldsRaw }>;
    };
    cottagesAndApartments: {
      edges: Array<{ node: AccommodationFieldsRaw }>;
    };
    hotels: {
      edges: Array<{ node: AccommodationFieldsRaw }>;
    };
    accommodationSearchPage: {
      id: string;
    };
  };
};

const ContentfulAccommodationPage: React.FC<
  ContentfulAccommodationsPageProps
> = ({
  data: {
    contentfulPage: { sections },
    iglus: { edges: igluCards },
    cottagesAndApartments: { edges: cottageAndApartmentCards },
    hotels: { edges: hotelCards },
    accommodationSearchPage,
  },
}) => {
  const [guests, setGuests] = React.useState<Guests>(initialGuests);
  const [dates, setDates] = React.useState<DateRange | undefined>();

  const accommodationSearchPageLink = useContentfulCallToAction({
    destination: {
      pageId: accommodationSearchPage.id,
    },
    target: 'self',
    title: localize('common.showAll'),
  }) as LinkProps<typeof Link>;

  const buildParams = React.useCallback(() => {
    const dateParams =
      dates && isValidDateRange(dates)
        ? `dates=${encodeDateRangeParams(dates)}`
        : undefined;
    const guestParams =
      guests.adults > 0 || guests.children > 0
        ? `guests=${encodeAsURIParams(guests)}`
        : undefined;

    const paramsString = [dateParams, guestParams]
      .filter((params) => !!params)
      .join('&');
    if (paramsString) {
      return `?${paramsString}`;
    }
    return '';
  }, [dates, guests]);

  const onSubmitSearch = React.useCallback(() => {
    if (accommodationSearchPageLink) {
      navigate(accommodationSearchPageLink.to + buildParams());
    }
  }, [accommodationSearchPageLink, buildParams]);

  //
  // Get random accomodations to show. If SSR, return empty array

  const rndIglus = useMemo(()=>!isDomAvailable() ? [] : igluCards.sort(() => 0.5 - Math.random()).slice(0, 3), []);
  const iglus = useContentfulTransformedCardGridData({
    cards: useAccommodationData(
      rndIglus,
    ),
    type: CardType.Accommodation,
  });

  const rndCottages = useMemo(()=>!isDomAvailable() ? [] : cottageAndApartmentCards.sort(() => 0.5 - Math.random()).slice(0, 3), []);
  const cottagesAndApartments = useContentfulTransformedCardGridData({
    cards: useAccommodationData(
      rndCottages,
    ),
    type: CardType.Accommodation,
  });

  const rndHotels = useMemo(()=>!isDomAvailable() ? [] : hotelCards.sort(() => 0.5 - Math.random()).slice(0, 3), []);
  const hotels = useContentfulTransformedCardGridData({
    cards: useAccommodationData(
      rndHotels,
    ),
    type: CardType.Accommodation,
  });


  const renderProp = React.useMemo(
    () => (
      <AccommodationSearchBar
        key="accommodation-search-bar"
        onGuestsChange={setGuests}
        guests={guests}
        dates={dates}
        onDatesChange={setDates}
        onSubmit={onSubmitSearch}
      />
    ),
    [dates, guests, onSubmitSearch],
  );

  const api = useHeaderStoreAPI();
  React.useEffect(() => {
    api.setState({ renderProp });

    return () => {
      api.setState({ renderProp: undefined });
    };
  }, [api, renderProp]);

  return (
    <main>
      {sections?.map((section: ContentfulSectionContainerTypes) => (
        <ContentfulSectionContainer key={section.id} {...section} />
      ))}

      <Section subPage>
        <SearchPageGrid noAside>
          <Styled.AccommodationSection>
            <CardGridHeader
              title={localize('accommodations.cottagesAndApartments')}
            >
              <ArrowLink
                label={localize('common.showAll')}
                {...{
                  ...accommodationSearchPageLink,
                  to: `${accommodationSearchPageLink.to}/?accType=RT&accType=PT&accType=MÖ&accType=AT&accType=HM&accType=BB`,
                }}
              />
            </CardGridHeader>
            <CardGrid cards={cottagesAndApartments} columns={3}/>
          </Styled.AccommodationSection>
        </SearchPageGrid>
      </Section>

      <Section subPage>
        <SearchPageGrid noAside>
          <Styled.AccommodationSection>
            <CardGridHeader title={localize('accommodations.iglus')}>
              <ArrowLink
                label={localize('common.showAll')}
                {...{
                  ...accommodationSearchPageLink,
                  to: `${accommodationSearchPageLink.to}/?accType=IG`,
                }}
              />
            </CardGridHeader>
            <CardGrid cards={iglus} columns={3}/>
          </Styled.AccommodationSection>
        </SearchPageGrid>
      </Section>

      <Section subPage>
        <SearchPageGrid noAside>
          <Styled.AccommodationSection>
            <CardGridHeader title={localize('accommodations.hotels')}>
              <ArrowLink
                label={localize('common.showAll')}
                {...{
                  ...accommodationSearchPageLink,
                  to: `${accommodationSearchPageLink.to}/?accType=DP`,
                }}
              />
            </CardGridHeader>
            <CardGrid cards={hotels} columns={3}/>
          </Styled.AccommodationSection>
        </SearchPageGrid>
      </Section>

      <Styled.BrowseAllButtonContainer>
        <TertiaryButton
          onClick={() => navigate(accommodationSearchPageLink.to)}
        >
          {localize('common.browseAll')} <Icon type={Icons.ChevronDownLarge} />
        </TertiaryButton>
      </Styled.BrowseAllButtonContainer>
    </main>
  );
};

export default ContentfulAccommodationPage;

export const contentfulPageQuery = graphql`
  query AccommodationPageById($id: String!, $locale: String!) {
    contentfulPage(id: { eq: $id }) {
      heroCarousel {
        ...contentfulHeroFields
      }
      sections {
        ...allPageSections
      }
      meta {
        ...contentfulPageMetaFields
      }
    }
    accommodationSearchPage: contentfulPage(
      slug: { in: ["accommodation-search", "majoitushaku"] }
      node_locale: { eq: $locale }
    ) {
      id
    }
    hotels: allAccommodation(
      limit: 40
      filter: { locale: { eq: $locale }, accType: { in: ["DP"] } }
    ) {
      edges {
        node {
          ...accommodationCardFields
        }
      }
    }
    cottagesAndApartments: allAccommodation(
      limit: 200
      filter: {
        locale: { eq: $locale }
        accType: { in: ["RT", "PT", "MÖ", "AT", "BB", "HM"] }
      }
    ) {
      edges {
        node {
          ...accommodationCardFields
        }
      }
    }
    iglus: allAccommodation(
      filter: { locale: { eq: $locale }, accType: { eq: "IG" } }
    ) {
      edges {
        node {
          ...accommodationCardFields
        }
      }
    }
  }
  fragment accommodationCardFields on Accommodation {
    id
    accommodationId
    amenities {
      Name
      Value
    }
    title
    description
    address
    accType
    beds
    extraBeds
    capacity {
      min
      max
    }
    maxPeople
    ratingRaw
    rating
    image: cardImage {
      childImageSharp {
        gatsbyImageData(
          width: 428
          breakpoints: [428]
          aspectRatio: 1.78333
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 50
          backgroundColor: "transparent"
        )
      }
    }
    fromModerAPI
  }
`;
