import React from 'react';
import Styled from './NumericInput.styled';

type NumericInputProps = {
  increaseLabel: string;
  decreaseLabel: string;
  value: number;
  className?: string;
  increase: () => void;
  decrease: () => void;
};

const NumericInput = ({
  increaseLabel,
  decreaseLabel,
  value,
  className,
  increase,
  decrease,
}: NumericInputProps) => {
  return (
    <Styled.Wrapper className={className}>
      <Styled.NumericInputContainer>
        <Styled.NumericInputButton
          role="button"
          aria-label={decreaseLabel}
          onClick={decrease}
        >
          -
        </Styled.NumericInputButton>
        <Styled.NumericInput>{value}</Styled.NumericInput>
        <Styled.NumericInputButton
          role="button"
          aria-label={increaseLabel}
          onClick={increase}
        >
          +
        </Styled.NumericInputButton>
      </Styled.NumericInputContainer>
    </Styled.Wrapper>
  );
};

export default NumericInput;
