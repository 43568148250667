import React from 'react';
import Styled from './Checkbox.styled';

export type CheckboxProps = {
  name: string;
  label: string;
  ariaLabel?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (value: boolean) => void;
};

const Checkbox = ({
  name,
  label,
  ariaLabel,
  defaultChecked,
  checked,
  onChange,
}: CheckboxProps): JSX.Element | null => {
  const [isChecked, setIsChecked] = React.useState(checked || defaultChecked);

  const onCheckboxChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.currentTarget;

      setIsChecked(checked);
      onChange && onChange(checked);
    },
    [onChange],
  );

  return (
    <Styled.Checkbox key={name}>
      <Styled.CheckboxLabel>
        <input
          type="checkbox"
          name={name}
          onChange={onCheckboxChange}
          checked={isChecked}
          aria-label={ariaLabel || label}
        />
        {label}
      </Styled.CheckboxLabel>
    </Styled.Checkbox>
  );
};

export default Checkbox;
