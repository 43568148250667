import styled from 'styled-components';
import { AppColor } from '../../../styles/colors';

const FilterGuestsForm = styled.div`
  padding: 1rem 2rem;
  position: relative;
  height: unset;
`;

type FilterGuestsFormProps = {
  isOpen: boolean;
};

const FilterGuestsFormContainer = styled.div<FilterGuestsFormProps>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: 1.5rem;
  background-color: white;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  box-shadow: rgb(45 81 123 / 25%) 0.625rem 0.625rem 3.125rem 0;
  padding: 1.5rem;
  min-width: 250px;
`;

const ToggleButton = styled.button`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
`;

const FieldSet = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${AppColor.Neutral700};
`;

export default {
  FilterGuestsForm,
  FilterGuestsFormContainer,
  FieldSet,
  ToggleButton,
};
