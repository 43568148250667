import React from 'react';
import { localize } from '../localization/i18n';

const useAccommodationFilterData = () => {
  const filterData = React.useMemo(() => {
    const types = [
      { id: 'AT', label: localize('accommodations.categories.AT') },
      { id: 'BB', label: localize('accommodations.categories.BB') },
      { id: 'DP', label: localize('accommodations.categories.DP') },
      { id: 'HM', label: localize('accommodations.categories.HM') },
      { id: 'IG', label: localize('accommodations.categories.IG') },
      { id: 'MÖ', label: localize('accommodations.categories.MÖ') },
      { id: 'PT', label: localize('accommodations.categories.PT') },
      { id: 'RT', label: localize('accommodations.categories.RT') },
    ];

    const roomCount = [
      {
        id: '1',
        value: 1,
        operator: '=',
        label: `1 ${localize('accommodations.capacity.Room')}`,
      },
      {
        id: '2',
        value: 2,
        operator: '=',
        label: `2 ${localize('accommodations.capacity.Rooms')}`,
      },
      {
        id: '3',
        value: 3,
        operator: '=',
        label: `3 ${localize('accommodations.capacity.Rooms')}`,
      },
      {
        id: '4',
        value: 4,
        operator: '>=',
        label: `4 ${localize('accommodations.capacity.RoomsMax')}`,
      },
    ];

    const rating = [
      { id: 1, label: '1' },
      { id: 2, label: '2' },
      { id: 3, label: '3' },
      { id: 4, label: '4' },
      { id: 5, label: '5' },
    ];

    const areas = [
      { id: 'IM', label: localize('accommodations.locations.IM') },
      { id: 'KI', label: localize('accommodations.locations.KI') },
      { id: 'KO', label: localize('accommodations.locations.KO') },
      { id: 'KÄ', label: localize('accommodations.locations.KÄ') },
      { id: 'LG', label: localize('accommodations.locations.LG') },
      { id: 'LJ', label: localize('accommodations.locations.LJ') },
      { id: 'LE', label: localize('accommodations.locations.LE') },
      { id: 'LR', label: localize('accommodations.locations.LR') },
      { id: 'ML', label: localize('accommodations.locations.ML') },
      { id: 'PE', label: localize('accommodations.locations.PE') },
      { id: 'RA', label: localize('accommodations.locations.RA') },
      { id: 'SI', label: localize('accommodations.locations.SI') },
      { id: 'TV', label: localize('accommodations.locations.TV') },
      { id: 'ER', label: localize('accommodations.locations.ER') },
      { id: 'UV', label: localize('accommodations.locations.UT') },
    ];

    const distances = [
      { id: '1', value: 1.0, label: `${localize('common.max')} 1 km` },
      { id: '3', value: 3.0, label: `${localize('common.max')} 3 km` },
      { id: '5', value: 5.0, label: `${localize('common.max')} 5 km` },
      { id: '10', value: 10.0, label: `${localize('common.max')} 10 km` },
      { id: '20', value: 20.0, label: `${localize('common.max')} 20 km` },
    ];

    const amenities = [
      {
        id: 'DishWashing',
        label: localize('accommodations.categories.DishWashing'),
      },
      {
        id: 'Washing',
        label: localize('accommodations.categories.Washing'),
      },
      {
        id: 'Electric_Sauna',
        label: localize('accommodations.categories.Electric_Sauna'),
      },
      {
        id: 'Wood_Heated_Sauna',
        label: localize('accommodations.categories.Wood_Heated_Sauna'),
      },
      {
        id: 'Animals',
        label: localize('accommodations.categories.Animals'),
      },
      {
        id: 'FirePlace',
        label: localize('accommodations.categories.FirePlace'),
      },
      {
        id: 'Internet',
        label: localize('accommodations.categories.Internet'),
      },
      {
        id: 'Jacuzzi',
        label: localize('accommodations.categories.Jacuzzi'),
      },
      {
        id: 'OwnBeach',
        label: localize('accommodations.categories.OwnBeach'),
      },
      {
        id: 'SkiPass',
        label: localize('accommodations.categories.SkiPass'),
      },
      {
        id: 'Disabled_Person',
        label: localize('accommodations.categories.Disabled_Person'),
      },
      {
        id: 'LogCabin',
        label: localize('accommodations.categories.LogCabin'),
      },
      {
        id: 'SkiMaintenance',
        label: localize('accommodations.categories.SkiMaintenance'),
      },
      { id: 'Kota', label: localize('accommodations.categories.Kota') },
    ];

    return {
      types,
      roomCount,
      rating,
      distances,
      areas,
      amenities,      
    };
  }, []);

  return filterData;
};

export default useAccommodationFilterData;
