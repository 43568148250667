import { Link } from "@Levi/components/common/link";
import React from 'react';
import { Slope } from '../../network/skiresort-api/types/SlopeDataTypes';
import { AppColor } from '../../styles/colors';
import { Icons } from '../../types/icon-types';
import RowStatusIcon from './RowStatusIcon';
import Styled from './SkiResortAmenities.styled';
import { RowProps } from './SkiResortAmenitiesList';

const BikeTrailRow = ({ data, locale }: RowProps<Slope>) => {
  const {
    difficultyColor,
    displayName,
    name,
    comment,
    length,
    hasLighting,
    inSnowPreparation,
    open,
    statusIcon,
  } = data;

  return (
    <Link to={"#" + name[locale]}>
    <Styled.Row>
      <Styled.SlopeDisplayName difficultyColor={difficultyColor}>
        {displayName}
      </Styled.SlopeDisplayName>
      <Styled.Info>
        <span>{name[locale]}</span>
        {length &&<span>{length}</span>}
      </Styled.Info>
      <Styled.StatusCell>
        {inSnowPreparation && (
          <RowStatusIcon type={Icons.Snowmaking} color={AppColor.LeviBlue} />
        )}
        {hasLighting && (
          <RowStatusIcon
            type={Icons.SlopeHasLighting}
            color={AppColor.LeviDarkBlue}
          />
        )}
        <Styled.Info style={{textAlign: "right"}}>
          {data.comment[locale] && <span>{data.comment[locale]}</span>}
          <span>{open.hours}</span>
        </Styled.Info>
        <RowStatusIcon {...statusIcon} />
      </Styled.StatusCell>
    </Styled.Row>
    </Link>
  );
};

export default BikeTrailRow;
