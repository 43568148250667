import styled from 'styled-components';

const AccommodationSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-top: 1rem;
  grid-auto-flow: column;
  grid-area: content;
`;

const BrowseAllButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  AccommodationSection,
  BrowseAllButtonContainer,
};
