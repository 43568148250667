import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link';
import { PageProps } from '../types';
import { Typography } from '../../../components/common/typography/Typography.styled';
import Styled from './ServiceListPage.styled';
import CardGridHeader from '../../../components/common/cardgrid/CardGridHeader';
import { MultiSelectDropdownProps } from '../../../components/common/dropdown/MultiSelectDropdown';
import useUrlParamAwareState from '../../../hooks/useUrlParamAwareState';
import { SearchPageGrid } from '../../../components/common/layout/Grid.styled';
import CardGrid from '../../../components/common/cardgrid/CardGrid';
import useContentfulTransformedCardGridData from '../../../hooks/useContentfulTransformedCardGridData';
import { CardProps, CardType } from '../../../components/common/card/Card';
import {
  BookingStatus,
  CardTagProps,
  Geolocation,
} from '../../../components/common/card/types';
import LabeledDropdown from '../../../components/common/dropdown/LabeledDropdown';
import {
  containsString,
  FilterFunc,
  isBookingStatusAvailableWithinDateRange,
  isTrueByValueOperatorPair,
  isInsideInterval,
  isLessThanOrEqualToAny,
  matchesAny,
  matchesAll,
} from '../../../hooks/useFilteredData';
import { ListIterator, SortOrder } from '../../../hooks/useSortedData';
import SearchFilters from '../../../components/common/list-filters/SearchFilters';
import AvailabilitySearchForm from '../../../components/common/list-filters/AvailabilitySearchForm';
import {
  DateRange,
  DateRangeValid,
  isValidDateRange,
} from '../../../utils/date-utils';
import StarRating, {
  RatingProps,
} from '../../../components/common/rating/StarRating';
import { ContentfulGatsbyImage } from '../../../types/images';
import FilterGuestsForm, {
  Guests,
} from '../../../components/common/list-filters/FilterGuestsForm';
import useAccommodationData, {
  AccommodationData,
  AccommodationFieldsRaw,
  resolvePriceForAccommodationReservation,
} from '../../../hooks/useAccommodationData';

import {
  dateParamsToDateRange,
  decodeURIParams,
  encodeAsURIParams,
  encodeDateRangeParams,
} from '../utils';
import usePagedData from '../../../hooks/usePagedData';
import { TertiaryButton } from '../../../components/common/button/Button';
import Icon from '../../../components/common/icon/Icon';
import { Icons } from '../../../types/icon-types';
import useAccommodationFilterData from '../../../hooks/useAccommodationFilterData';
import { localize } from '../../../localization/i18n';
import ClusterMap from '../../../components/map/ClusterMap';
import { MetaImage } from '../../meta';
import { isDomAvailable } from '../../../utils/env-utils';
import { isDate, isEqual } from 'date-fns';
import Checkbox from '../../../components/common/checkbox/Checkbox';
import ModerAPI from '../../../network/moder-api/ModerAPI';

export type NumRange = {
  min: number;
  max: number;
};

export type AccommodationImage = {
  childImageSharp: ContentfulGatsbyImage;
};

export type AccommodationFields = {
  id: string;
  accommodationId: string;
  destinationCode: string;
  productCode: string;
  title: string;
  description?: string;
  mainContent?: string;
  areaName?: string;
  areaCode: string;
  distance: number;
  accType: string;
  rooms: number;
  rating?: RatingProps;
  ratingRaw: string;
  location?: Geolocation;
  address?: string;
  postCode?: string;
  tags?: CardTagProps[];
  image?: AccommodationImage;
  images?: [string] | string[];
  metaImage?: MetaImage;
  capacity: NumRange;
  maxPeople: number;
  beds: number;
  extraBeds: number;
  amenities: [string] | string[];
  bookingStatus?: BookingStatus[];
  fromModerAPI: boolean;
};

type AccommmodationPriceForDateRange = {
  // Accommodation DTO's cache resolved price for certain date range on runtime
  resolvedPrice?: number | undefined;
  resolvedPriceDateRange?: DateRangeValid | undefined;
};

export type AccommodationFieldsWithPrices = AccommodationFields &
  AccommmodationPriceForDateRange;

type SearchAccommodationsPageProps = PageProps & {
  data: {
    accommodations: {
      edges: Array<{ node: AccommodationFieldsRaw }>;
    };
  };
};

const initialGuests: Guests = {
  adults: 0,
  children: 0,
};

const initialOffset = 0;
const itemLimit = 20;

const SearchAccommodationsPage: React.FC<SearchAccommodationsPageProps> = ({
  data: {
    accommodations: { edges: cards },
  },
  location,
}) => {
  // read/write search term from/to query string as string[], ignore history so that browser back button won't mind about user typing search term letters
  const [searchTermQuery, setSearchTermQuery] = useUrlParamAwareState({
    location,
    paramName: 'q',
    initialValue: [''],
    nohistory: true,
  });

  // page state holding the current searchTerm for less latency when typing
  const [searchTerm, setSearchTerm] = React.useState(searchTermQuery);

  // debounced effect to update query string, which triggers when searchTerm is changed
  useEffect(() => {
    const timeoutId =
      searchTerm[0] != searchTermQuery[0] &&
      setTimeout(() => setSearchTermQuery(searchTerm), 500);
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [searchTerm, searchTermQuery, setSearchTermQuery]);

  // a more complex approach to fix back button issues; not relevant if nohistory patch works
/*
  const lastSearchTermValue = React.useRef(searchTerm);
  const lastSeachTermQueryUpdateTimeoutId = React.useRef<
    false | NodeJS.Timeout
  >(false);

  // debounced effect to update query string, which triggers when searchTerm is changed
  useEffect(() => {
    if (searchTerm[0] != searchTermQuery[0]) {
      // one term did change
      if (lastSearchTermValue.current === searchTerm) {
        lastSearchTermValue.current = searchTermQuery;
        // query changed
        setSearchTerm(searchTermQuery);
      } else {
        // search term input changed
        lastSeachTermQueryUpdateTimeoutId.current =
          searchTerm[0] != searchTermQuery[0] &&
          setTimeout(() => {
            lastSearchTermValue.current = searchTerm;
            setSearchTermQuery(searchTerm);
          }, 500);
        return () => {
          if (lastSeachTermQueryUpdateTimeoutId.current)
            clearTimeout(lastSeachTermQueryUpdateTimeoutId.current);
        };
      }
    }
  }, [searchTerm, searchTermQuery, setSearchTermQuery]);
*/
  const [sort, setSort] = useUrlParamAwareState({
    location,
    paramName: 'sort',
    initialValue: ['title-asc'],
  });

  const [dates, setDates] = useUrlParamAwareState({
    location,
    paramName: 'dates',
    initialValue: [],
  });

  const [guests, setGuests] = useUrlParamAwareState({
    location,
    paramName: 'guests',
    initialValue: [encodeAsURIParams(initialGuests)],
  });

  const [selectedTypes, setSelectedTypes] = useUrlParamAwareState({
    location,
    paramName: 'accType',
    initialValue: [],
  });

  const [selectedRoomCount, setSelectedRoomCount] = useUrlParamAwareState({
    location,
    paramName: 'rooms',
    initialValue: [],
  });

  const [selectedAmenities, setSelectedAmenities] = useUrlParamAwareState({
    location,
    paramName: 'amenities',
    initialValue: [],
  });

  const [selectedRating, setSelectedRating] = useUrlParamAwareState({
    location,
    paramName: 'ratingRaw',
    initialValue: [],
  });

  const [selectedArea, setSelectedArea] = useUrlParamAwareState({
    location,
    paramName: 'areaCode',
    initialValue: [],
  });

  const [selectedDistance, setSelectedDistance] = useUrlParamAwareState({
    location,
    paramName: 'distance',
    initialValue: [],
  });

  const [extendedAvailabilities, setExtendedAvailabilities] =
    useUrlParamAwareState({
      location,
      paramName: 'extended',
      initialValue: ['true'],
    });

  // data model for filters
  const filterData = useAccommodationFilterData();

  // parsed and transformed accommodation data (does not change)
  const accommodationData = useAccommodationData(cards);

  const [accommodationDataWithPrices, setAccommodationDataWithPrices] =
    React.useState<AccommodationData>([]);

  useEffect(() => {
    const dateRange = dateParamsToDateRange(dates);
    if (dateRange && isValidDateRange(dateRange)) {
      //TODO Remove the console.log below if the new pricing works ok
      console.log(
        `dates changed to ${dates.join(
          ',',
        )}, should reload prices for moder targets`,
      );

      // trigger a repaint with empty data set (next repaint when prices are resolved)
      setAccommodationDataWithPrices([]);

      // resolve new prices
      const newAccommodationDataWithPrices: AccommodationData =
        accommodationData.map((accommodationData) => {
          const node = accommodationData.node;

          // a lot of memory consuming cloning takes place here!
          const nodeWithPrices = {
            ...node,
            resolvedPrice: resolvePriceForAccommodationReservation(
              node.bookingStatus || [],
              dateRange,
            ),
            resolvedPriceDateRange: dateRange,
          };
          return {
            node: nodeWithPrices,
          };
        });

      const fetchModerPrices = async (moderItems: AccommodationData) => {
        // collect items into a hash
        const moderItemsHash = moderItems.reduce(
          (res: Record<string, any>, value) => {
            res[value.node.accommodationId] = value;
            return res;
          },
          {},
        );

        // TODO show preloader
        const moderPrices = await ModerAPI.getPrices(
          moderItems.map((item) =>
            item.node.accommodationId.replace('moder-', ''),
          ),
          dateRange as DateRangeValid,
        );
        // TODO hide preloader when finished

        moderPrices.forEach((priceData) => {
          const moderItem = moderItemsHash[`moder-${priceData.room_type_id}`];
          moderItem.node.resolvedPrice = priceData.total_price / 100.0;
        });

        // trigger a repaint with modified data
        setAccommodationDataWithPrices(newAccommodationDataWithPrices);
      };

      // TODO implement cancellation for async operation
      // this will update data asynchronously

      const moderItems = newAccommodationDataWithPrices.filter(
        (item) => !!item.node.fromModerAPI,
      );

      if (moderItems.length > 0) {
        // this would error if moderItems is an empty array
        fetchModerPrices(moderItems);
      } else {
        // no moder items, so just update the data
        setAccommodationDataWithPrices(newAccommodationDataWithPrices);
      }

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    } else {
      // no dates set, reset to original data
      setAccommodationDataWithPrices(accommodationData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates.toString(), accommodationData]);

  // data model for AccommodationCards
  const data = useContentfulTransformedCardGridData({
    cards: accommodationDataWithPrices,
    type: CardType.Accommodation,
  });

  const filters = React.useMemo<FilterFunc[]>(() => {
    const selectedGuests = decodeURIParams(guests);
    const defaultFilters = [
      containsString('title', searchTerm[0]),
      isInsideInterval(
        'capacity',
        selectedGuests.adults + selectedGuests.children,
      ),
      matchesAny('accType', selectedTypes),
      isTrueByValueOperatorPair(
        'rooms',
        filterData.roomCount.filter((item) =>
          selectedRoomCount.includes(item.id),
        ),
      ),
      matchesAny('ratingRaw', selectedRating),
      matchesAny('areaCode', selectedArea),
      isLessThanOrEqualToAny(
        'distance',
        filterData.distances
          .filter((item) => selectedDistance.includes(item.id))
          .map((val) => val.value),
      ),
      matchesAll('amenities', selectedAmenities),
      // matchesAny('availabilities', selectedAvailabilities),
    ];
    const dateRange = dateParamsToDateRange(dates);
    if (dateRange && isValidDateRange(dateRange)) {
      const extend = extendedAvailabilities.includes('true');
      return [
        ...defaultFilters,
        isBookingStatusAvailableWithinDateRange(
          'bookingStatus',
          dateRange,
          extend,
        ),
      ];
    }
    return defaultFilters;
  }, [
    dates,
    filterData,
    guests,
    searchTerm,
    selectedAmenities,
    selectedArea,
    selectedDistance,
    selectedRating,
    selectedRoomCount,
    selectedTypes,
    extendedAvailabilities,
  ]);

  const onResetFiltersClick = React.useCallback(() => {
    navigate(`${location.pathname}`, {
      state: { disableScrollUpdate: true },
    });
  }, [location.pathname]);

  const sortParamRef = React.useRef<string>('title');
  const orderParamRef = React.useRef<SortOrder>('asc');
  const dateParamRef = React.useRef<DateRange | undefined>();

  const dateRange = React.useMemo(() => {
    const rawRange = dateParamsToDateRange(dates);
    const { start: rawStart, end: rawEnd } = rawRange || {};
    const { start, end } = dateParamRef.current || {};
    const bothStartsAreValid = isDate(rawStart) && isDate(start);
    const bothEndsAreValid = isDate(rawEnd) && isDate(end);
    const bothStartsAreEqual =
      (bothStartsAreValid && isEqual(rawStart as Date, start as Date)) ||
      rawStart === start;
    const bothEndsAreEqual =
      (bothEndsAreValid && isEqual(rawEnd as Date, end as Date)) ||
      rawEnd === end;
    if (!(bothStartsAreEqual || bothEndsAreEqual)) {
      dateParamRef.current = rawRange;
    }
    return dateParamRef.current;
  }, [dates]);

  const byPrice = React.useCallback(
    () => (item: CardProps<CardType.Accommodation>) => {
      return item.resolvedPrice;
      //TODO Remove the commented block below if new pricing works ok
      // return resolvePriceForAccommodationReservation(
      //   item.bookingStatus,
      //   dateRange,
      // );
    },
    [],
  );

  const [sortParam, orderParam] = React.useMemo(() => {
    const [rawBy, order] = sort[0].split('-');
    if (rawBy !== sortParamRef.current || orderParamRef.current !== order) {
      sortParamRef.current = rawBy;
      orderParamRef.current = order as SortOrder;
    }

    return [sortParamRef.current, orderParamRef.current];
  }, [sort]);

  const [sortBy, setSortBy] = React.useState<
    string | ListIterator<CardProps<CardType.Accommodation>, unknown>
  >('title');

  const { dataSlice, reSort, maxItems, fetchMore, hasMore } = usePagedData({
    data: isDomAvailable() ? data : [],
    sortBy,
    initialSortOrder: orderParamRef.current,
    filters,
    initialOffset,
    itemLimit,
  });

  const changeSortOrder = React.useCallback(
    (
      by: string | ListIterator<CardProps<CardType.Accommodation>, unknown>,
      order: SortOrder,
    ) => {
      setSortBy(by);
      reSort(order);
    },
    [reSort],
  );

  React.useEffect(() => {
    const by = sortParam === 'byPrice' ? byPrice : sortParam;
    changeSortOrder(by, orderParam);
  }, [byPrice, changeSortOrder, orderParam, sortParam]);

  const listFilters = React.useMemo<MultiSelectDropdownProps[]>(
    () => [
      {
        name: 'typeFilter',
        label: localize('filters.listFilters.accommodationType'),
        items: filterData.types.map(({ id, label }) => ({
          value: String(id),
          label: label,
        })),
        onChange: setSelectedTypes,
        value: selectedTypes,
        variant: 'primary',
      },
      {
        name: 'roomCountFilter',
        label: localize('filters.listFilters.roomCount'),
        items: filterData.roomCount.map(({ id, label }) => ({
          value: String(id),
          label: label,
        })),
        onChange: setSelectedRoomCount,
        value: selectedRoomCount,
        variant: 'primary',
      },
      {
        name: 'amenitiesFilter',
        label: localize('filters.listFilters.amenities'),
        items: filterData.amenities.map(({ id, label }) => ({
          value: String(id),
          label: label,
        })),
        onChange: setSelectedAmenities,
        value: selectedAmenities,
        variant: 'primary',
      },
      {
        name: 'ratingFilter',
        label: localize('filters.listFilters.rating'),
        items: filterData.rating.map(({ id, label }) => ({
          value: String(id),
          label: label,
          component: <StarRating rating={label} />,
        })),
        onChange: setSelectedRating,
        value: selectedRating,
        variant: 'primary',
      },
      {
        name: 'areaFilter',
        label: localize('filters.listFilters.location'),
        items: filterData.areas.map(({ id, label }) => ({
          value: String(id),
          label: label,
        })),
        onChange: setSelectedArea,
        value: selectedArea,
        variant: 'primary',
      },
      {
        name: 'distanceFilter',
        label: localize('filters.listFilters.distance'),
        items: filterData.distances.map(({ id, label }) => ({
          value: String(id),
          label: label,
        })),
        onChange: setSelectedDistance,
        value: selectedDistance,
        variant: 'primary',
      },
    ],
    [
      filterData,
      setSelectedTypes,
      selectedTypes,
      setSelectedRoomCount,
      selectedRoomCount,
      setSelectedAmenities,
      selectedAmenities,
      setSelectedRating,
      selectedRating,
      setSelectedArea,
      selectedArea,
      setSelectedDistance,
      selectedDistance,
    ],
  );

  const mapData = React.useMemo(() => {
    return dataSlice.map((item) => {
      return {
        ...item,
        guests: decodeURIParams(guests),
        dates: dateRange,
        //dates: dateParamsToDateRange(dates) as unknown as Required<DateRange>,
      };
    });
  }, [dataSlice, dateRange, guests]);

  return (
    <main>
      <Styled.ContentContainer>
        <SearchPageGrid>
          <Styled.MainSection>
            <Typography.Display>
              {localize('accommodations.headline')}
            </Typography.Display>

            <Styled.PrimaryFilters>
              <AvailabilitySearchForm
                selectedRange={
                  dateParamsToDateRange(dates) as
                    | Required<DateRange>
                    | undefined
                }
                onSelectedDateRangeChange={(range) =>
                  range && isValidDateRange(range)
                    ? setDates([encodeDateRangeParams(range)])
                    : setDates([])
                }
              />
              <Styled.VerticalDivider />
              <FilterGuestsForm
                onChange={(guests) => setGuests([encodeAsURIParams(guests)])}
                guests={decodeURIParams(guests)}
              />
            </Styled.PrimaryFilters>

            <SearchFilters
              filters={listFilters}
              searchTerm={searchTerm[0]}
              setSearchTerm={(newValue) => setSearchTerm([newValue])}
              onResetClick={onResetFiltersClick}
            >
              <Checkbox
                name="extensionFilter"
                label={localize('filters.listFilters.freeOnly')}
                checked={!extendedAvailabilities.includes('true')}
                onChange={(value) => {
                  setExtendedAvailabilities(value ? ['false'] : ['true'])
                }
                }
              />
            </SearchFilters>

            <CardGridHeader
              title={localize('searchResults.seachResultsTitle')}
              count={maxItems}
            >
              <LabeledDropdown
                variant="secondary"
                value={sort[0] || 'title-asc'}
                name="sortBy"
                dropdownLabel={localize('searchResults.sort.label')}
                label=""
                onChange={(value) => {
                  /*
                  const [rawBy, order] = value.split('-');
                  const by = rawBy === 'byPrice' ? byPrice : rawBy;
                  changeSortOrder(by, order as SortOrder);
                  */
                  setSort([value]);
                  //reSort(value as SortOrder)
                }}
                items={[
                  {
                    value: 'title-asc',
                    label: localize('searchResults.sort.textAsc'),
                  },
                  {
                    value: 'title-desc',
                    label: localize('searchResults.sort.textDesc'),
                  },
                  {
                    value: 'byPrice-asc',
                    label: localize('searchResults.sort.priceAsc'),
                  },
                  {
                    value: 'byPrice-desc',
                    label: localize('searchResults.sort.priceDesc'),
                  },
                ]}
              />
            </CardGridHeader>
            <CardGrid
              cards={dataSlice.map((accommodation, index) => ({
                ...accommodation,
                locationMarkerIndex: index + 1,
                guests: decodeURIParams(guests) as Guests,
                selectedDateRange: dateRange as Required<DateRange> | undefined,
              }))}
              columns={2}
              mobileScrollable={false}
            />
            {hasMore && (
              <Styled.LoadMoreButtonContainer>
                <TertiaryButton onClick={fetchMore}>
                  {localize('searchResults.loadMore')}{' '}
                  <Icon type={Icons.ChevronDownLarge} />
                </TertiaryButton>
              </Styled.LoadMoreButtonContainer>
            )}
          </Styled.MainSection>

          <Styled.MapSectionSticky>
            <ClusterMap data={mapData} />
          </Styled.MapSectionSticky>
        </SearchPageGrid>
      </Styled.ContentContainer>
    </main>
  );
};

export default SearchAccommodationsPage;

export const contentfulPageQuery = graphql`
  query SearchAccommodationsPageById($id: String!, $locale: String!) {
    contentfulPage(id: { eq: $id }) {
      meta {
        ...contentfulPageMetaFields
      }
    }
    # Fetch all accommodations
    accommodations: allAccommodation(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...accommodationCardFields
          areaName
          areaCode
          bookingStatus
          location {
            lat
            lon
          }
        }
      }
    }
  }
`;
