import { AccommodationFields, AccommodationFieldsWithPrices } from '../contentful/templates/serviceListPages/searchAccommodationsPage';
import { BookingStatus, Geolocation } from '../components/common/card/types';
import React from 'react';
import {
  DateRange,
  DateRangeValid,
  differenceInFullDays,
  isValidDateRange,
} from '../utils/date-utils';

export type AccommodationData = Array<{ node: AccommodationFieldsWithPrices }>;

export type Amenity = {
  Name: string;
  Value: string;
};

export type AccommodationFieldsRaw = Omit<
  AccommodationFields,
  'amenities' | 'beds' | 'extraBeds' | 'bookingStatus' | 'rooms'
> & {
  amenities: Amenity[];
  beds: string;
  extraBeds: string;
  bookingStatus: number[][];
  rooms: string;
  fromModerAPI: boolean;
};
export type AccommodationDataRaw = Array<{ node: AccommodationFieldsRaw }>;

export const resolveAmenities = (amenities: Amenity[], accType: string) => {
  const results = [accType];

  amenities?.forEach((amenity) => {
    if (amenity.Name === 'Animals') {
      if (amenity.Value === '0') {
        results.push('NoAnimals');
      } else {
        results.push('Animals');
      }
    } else if (amenity.Value === '1') {
      results.push(amenity.Name);
    }
  });
  return results;
};

export const resolveRooms = (amenities: Amenity[]): number => {
  const roomsAmenity =
    amenities && amenities.find((amenity) => amenity.Name === 'Rooms');

  return roomsAmenity ? parseInt(roomsAmenity.Value) : 0;
};

export const resolveDistance = (amenities: Amenity[]): number => {
  const distanceAmenity =
    amenities && amenities.find((amenity) => amenity.Name === 'Distance');

  return distanceAmenity
    ? parseFloat(distanceAmenity.Value.replace(',', '.'))
    : 0.0;
};

const coords: { [key: string]: boolean } = {};

const duplicateLocationShifted = (
  location: Geolocation | undefined,
): Geolocation => {
  if (!location) {
    return {
      lon: 0,
      lat: 0,
    };
  }
  const lng =
    `${location.lat}-${location.lon}` in coords
      ? location.lon + (Math.random() - 0.5) / 1500
      : location.lon;
  const lat =
    `${location.lat}-${location.lon}` in coords
      ? location.lat + (Math.random() - 0.5) / 1500
      : location.lat;

  coords[`${lat}-${lng}`] = true;
  return {
    lon: lng,
    lat: lat,
  };
};

const useAccommodationData = (
  data: AccommodationDataRaw,
): AccommodationData => {
  // const [results, setResults] = React.useState<AccommodationData>([]);
  // const [done, setDone] = React.useState<boolean>(false);

  const reFormatData = React.useCallback((data: AccommodationDataRaw) => {
    return data.map((nodeItem) => {
      const item = nodeItem.node;
      let noPricesAvailable = true;
      const bookingStatus = (item.bookingStatus || []).map(
        (statusParts: number[]) => {
          // booking status format
          //  [yy, mm, dd, b, €0, ... , €7] for available days preceding another available day
          // where
          //  yy = 2-digit year
          //  mm = 2-digit month 01 = jan
          //  dd = 2-digit day of month
          //  b = sum of
          //        1 - allowed start of min n-days reservation
          //        2 - allowed start of 7 days reservation
          //        4 - allowed start of >7 days reservation
          //        8 - next day is allowed end day of reservation
          //        n << 4 = n the minimum days for reservation starting on this day (this would fit to the same byte with b)
          //  €n - the price available for given length category n for a reservation starting on this date

          // parse date parts
          const [year, month, day, flags, ...centPrices] = statusParts;

          if (centPrices?.length) noPricesAvailable = false;

          const fromModerAPI = !!item.fromModerAPI;
          // convert into BookingStatus
          return {
            date: new Date(2000 + year, month - 1, day),
            lessThanWeekAllowed: !!(flags & 1),
            weekAllowed: !!(flags & 2),
            extendedWeekAllowed: !!(flags & 4),
            nextDayEndingAllowed: !!(flags & 8),
            minStay: (flags & 0b11110000) >> 4,
            maxStay: (!fromModerAPI && 255) || (flags & 0b1111111100000000) >> 8, 
            checkinDenied: fromModerAPI && !!(flags & 65536), 
            checkoutDenied: fromModerAPI && !!(flags & 131072), 
            prices: centPrices.map((cents) => cents / 100),
          } as BookingStatus;
        },
      );
      return {
        node: {
          ...item,
          rooms: resolveRooms(item.amenities),
          distance: resolveDistance(item.amenities),
          beds: parseInt(item.beds),
          extraBeds: parseInt(item.extraBeds),
          rating: item.rating,
          location: duplicateLocationShifted(item.location),
          amenities: resolveAmenities(item.amenities, item.accType),
          bookingStatus,
          noAvailabilitiesAvailable: !item.bookingStatus?.length,
          noPricesAvailable,
        },
      };
    });
  }, []);

  // This older structure made useAccommodationData return null on the first 
  // time around, then the actual data set. Why? (Mikko 17.11.2022)
  // React.useEffect(() => {
  //   if (done) {
  //     return;
  //   }
  //   const formattedData = reFormatData(data);
  //   setResults(formattedData);
  //   setDone(true);
  // }, [data, done, reFormatData]);

  // Parse data only once (because it's from graphql query and immutable)
  const results = React.useMemo(() => reFormatData(data), [data, reFormatData]);
  // Always return the same object
  return results;
};

export const resolveCachedPriceForDateRange = (
  accommodationData: AccommodationFieldsWithPrices,
  dateRange: DateRangeValid | undefined,
) => {
  if (accommodationData)
    if (
      accommodationData.resolvedPriceDateRange?.start?.getTime() ===
        dateRange?.start?.getTime() &&
      accommodationData.resolvedPriceDateRange?.end?.getTime() ===
        dateRange?.end?.getTime()
    ) {
      return accommodationData.resolvedPrice;
    } else {
      return 0;
    }
};

export const resolvePriceForAccommodationReservation = (
  bookingStatuses: BookingStatus[],
  dateRange: DateRange | undefined,
): number | undefined => {
  // assert(dateRange is set, so are it's start and end. !!item.bookingStatus)
  if (!dateRange || !isValidDateRange(dateRange) || !bookingStatuses) {
    return undefined;
  }

  const bookingStatusStartIndex = bookingStatuses.findIndex(
    (status: BookingStatus) =>
      status.date.getTime() === dateRange.start.getTime(),
  );

  // reduce one day from end day since ending day may not have pricing information if it's the beginning of the next reservation
  const lastRequiredEndDay = new Date(
    dateRange.end.getFullYear(),
    dateRange.end.getMonth(),
    dateRange.end.getDate() - 1,
  );

  const bookingStatusEndIndex = bookingStatuses.findIndex(
    (status: BookingStatus) =>
      status.date.getTime() === lastRequiredEndDay.getTime(),
  );

  const bookingNights = Math.max(
    differenceInFullDays(dateRange.start, dateRange.end),
    1,
  );

  // both dates must be found from bookingStatuses
  if (bookingStatusEndIndex === -1 || bookingStatusStartIndex === -1)
    return undefined;

  // all items between bookingStatuses must exist (otherwise there would be gaps in pricing)
  if (bookingStatusEndIndex - bookingStatusStartIndex != bookingNights - 1)
    return undefined;

  // use week price for full weeks, then PriceDX for remaining nights
  const weekPriceLimit =
    bookingNights > 7 ? bookingNights - (bookingNights % 7) : bookingNights;
  let price = 0;
  for (let nightIndex = 0; nightIndex < bookingNights; nightIndex++) {
    const nightlyPrice =
      bookingStatuses[bookingStatusStartIndex + nightIndex].prices[
        nightIndex < weekPriceLimit ? Math.min(6, bookingNights - 1) : 7
      ];
    if (!nightlyPrice) return undefined;
    price += nightlyPrice;
  }

  return Math.round(price);
};

export default useAccommodationData;
