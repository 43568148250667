import React from 'react';
import MainNavigation from '../../components/navigation/main/MainNavigation';
import { NavLinkPropsWithSubpages } from '../../components/navigation/main/MainNavigationPropTypes';
import useContentfulNavigationData, {
  ContentfulRoutes,
} from '../../hooks/useContentfulNavigationData';
import useLocaleLink from '../../hooks/useLocaleLink';
import { Theme } from '../../styles/themes';
import { PageContextTypes } from '../templates/types';
import { restaurantCategoriesToLocalizedList } from '../data/restaurantCategories';
import { accommodationSubmenuElements } from '../data/accommodationCategories';
import useLocale from '../../localization/useLocale';
import { localize } from '../../localization/i18n';

export type ContentfulNavigationProps = {
  theme: Theme;
  urlOrigin?: string;
  localeLinkPath?: string;
  hideLogo?: boolean;
  hideWeather?: boolean;
  hideLocaleLink?: boolean;
  hideSearch?: boolean;
};

const ContentfulNavigation = ({
  pageId,
  staticPageKey,
  theme,
  urlOrigin = '',
  localeLinkPath,
  hideLogo,
  hideWeather,
  hideLocaleLink,
  hideSearch,
}: Omit<PageContextTypes, 'breadcrumbs'> & ContentfulNavigationProps) => {
  const locale = useLocale();
  const localeLink = useLocaleLink(pageId, staticPageKey, localeLinkPath);
  const siteData = useContentfulNavigationData();

  // Format page structure for navigation
  const formatPages = (page: ContentfulRoutes, basePath: string) => {
    if (page.hideFromLocalization && locale !== 'fi') {
      return {
        label: '',
        to: ''
      };
    }
    
    const path = `${basePath}/${page.slug}`;
    const subpageObj: NavLinkPropsWithSubpages = {
      label: page.label,
      to: path,
    };

    // ===== Handle eat-and-drink-page =====
    // Eat-and-drink page passes its subpages and creates nav-links to search-page with categories as query strings

    if (page.type === 'eat-and-drink') {
      const searchPageSlug = page?.subpages?.find(
        (subpage) => subpage.type === 'eat-and-drink-search',
      )?.slug;
      if (!searchPageSlug) {
        console.warn(
          'Eat-and-drink-page missing search-page. Skipping search-page for main navigation.',
        );
        return subpageObj;
      }

      subpageObj.subpages = restaurantCategoriesToLocalizedList().map(
        (category) => ({
          label: category.label,
          to: `${path}/${searchPageSlug}?category=${category.value}`,
          icon: category.icon,
        }),
      );
    }
    // =================
    // ===== Handle accommodation-page =====
    // Accommodation page passes its subpages and creates nav-links to search-page with categories as query strings

    if (page.type === 'accommodation') {
      const searchPageSlug = page?.subpages?.find(
        (subpage) => subpage.type === 'accommodation-search',
      )?.slug;
      if (!searchPageSlug) {
        console.warn(
          'Accommodation-page missing search-page. Skipping search-page for main navigation.',
        );
        return subpageObj;
      }

      subpageObj.subpages = accommodationSubmenuElements?.map(
        (accSubMenuElem) => ({
          label: localize(`accommodations.submenu.${accSubMenuElem.key}`),
          to: `${path}/${searchPageSlug}?accType=${accSubMenuElem.value.join(
            '&accType=',
          )}`,
          icon: accSubMenuElem.icon,
        }),
      );
    }
    // =================

    if (page.subpages && !subpageObj.subpages) {
      // skip pages based on contentful's hiddenInMainNav and hideFromLocalization fields
      const lowerLevelNavigationPages = page.subpages.filter(
        (page: ContentfulRoutes) => !page.hiddenInMainNav && !(page.hideFromLocalization && locale !== 'fi'),
      );
      const subpages = lowerLevelNavigationPages.map(
        (subpage: ContentfulRoutes) => formatPages(subpage, path),
      );
      subpageObj.subpages = subpages;
    }

    return subpageObj;
  };

  const getPageLinks = () => {
    if (!siteData?.subpages) {
      console.warn('Cannot get siteData for navigation');
      return [];
    }
    const rootPath = locale === 'fi' ? urlOrigin : `${urlOrigin}/${locale}`;
    // skip pages based on contentful's hiddenInMainNav and hideFromLocalization fields
    const topLevelNavigationPages = siteData.subpages.filter(
      (page: ContentfulRoutes) => !page.hiddenInMainNav && !(page.hideFromLocalization && locale !== 'fi'),
    );

    const pageLinks: NavLinkPropsWithSubpages[] = topLevelNavigationPages.map(
      (page: ContentfulRoutes) => {
        return formatPages(page, rootPath);
      },
    );
    return pageLinks;
  };

  const pageLinks = getPageLinks();

  return (
    <MainNavigation
      pageLinks={pageLinks}
      homePageLink={{
        to: locale === 'fi' ? `${urlOrigin}/` : `${urlOrigin}/${locale}`,
        label: localize('common.homePage'),
      }}
      localeLink={localeLink}
      weatherCamerasLink={{
        to: `${urlOrigin}/${localize('weather.weatherCamerasSlug')}`,
        label: localize('weather.weatherCamerasLabel'),
      }}
      theme={theme}
      hideLogo={hideLogo}
      hideWeather={hideWeather}
      hideLocaleLink={hideLocaleLink}
      hideSearch={hideSearch}
    />
  );
};

export default ContentfulNavigation;
