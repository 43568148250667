import { DateRangeValid, getLocalSQLDate } from '../../utils/date-utils';
import { RoomPriceDataType } from './types/RoomPriceDataType';
const baseURL = process.env.GATSBY_MODER_API_URL;
const moderApiKey = process.env.GATSBY_MODER_API_KEY;
const moderDestinationId = process.env.GATSBY_MODER_DESTINATION_ID;

class ModerAPI {
  static async get(resource: string, params: Record<string, any>) {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${moderApiKey}`);
    headers.append('Content-Type', 'application/json');

    const requestOptions: RequestInit = {
      method: 'GET',
      headers,
      redirect: 'follow',
    };

    const url = new URL(`${baseURL}${resource}`);

    // filter undefined params
    const undefinedParams = Object.entries(params).filter(
      ([, value]) => value === undefined,
    );
    undefinedParams.forEach(([key]) => delete params[key]);

    // filter array-like params
    const arrayParams = Object.entries(params).filter(([, value]) =>
      Array.isArray(value),
    );
    arrayParams.forEach(([key]) => delete params[key]);

    // remap array-like params to the query string each value individually
    url.search = arrayParams.reduce((search, [key, value]) => {
      return (
        search + value.map((v: string) => `&${key}=${encodeURIComponent(v)}`).join('')
      );
    }, new URLSearchParams(params).toString());

    const response = await fetch(url, requestOptions);
    if (response.status >= 200 && response.status <= 299) {
      return await response.json();
    } else {
      throw Error(
        `ModerAPI request to ${url} failed with status ${response.status}`,
      );
    }
  }

  static async getPrices(
    roomTypes: string[],
    dateRange: DateRangeValid,
    guestAdults?: number,
    guestChildren?: number,
  ): Promise<RoomPriceDataType[]> {
    const rawResult = await ModerAPI.get('prices', {
      destination: moderDestinationId,
      //TODO ensure that dates are on Finnish time zone
      date_start: getLocalSQLDate(dateRange.start),
      date_end: getLocalSQLDate(dateRange.end), 
      guest_adults: guestAdults,
      guest_children: guestChildren,
      'room_types[]': roomTypes,
    });

    return rawResult.data || [];
  }
}

export default ModerAPI;
