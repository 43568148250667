import React from 'react';
import { graphql } from 'gatsby';
import { PageContextTypes, PageProps } from './types';
import MainHeader from '../../components/header/PageHeader';
import Section from '../../components/section/Section';
import {
  SubpageGrid,
  SubpageGridElement,
} from '../../components/common/layout/Grid.styled';
import TextContent from '../../components/common/textContent/TextContent';
import { Typography } from '../../components/common/typography/Typography.styled';
import StarRating from '../../components/common/rating/StarRating';
import Styled from './accommodationPage.styled';
import { resolveTagByKey, resolveTags } from '../data/transformers/utils';
import { accommodationCategories } from '../data/accommodationCategories';
import CardTags from '../../components/common/card/CardTags';
import Grid from '../../components/common/layout/Grid';
import MapContainer from '../../components/common/map/MapContainer';
import { differenceInFullDays } from '../../utils/date-utils';
import {
  resolveAmenities,
  resolveRooms,
  AccommodationFieldsRaw,
} from '../../hooks/useAccommodationData';
import Icon, { IconSize } from '../../components/common/icon/Icon';
import { Icons } from '../../types/icon-types';
import { localize } from '../../localization/i18n';
import { Amenity } from '../../hooks/useAccommodationData';
import useBreakpoints from '../../hooks/useBreakpoints';
import Gallery from '../../components/gallery/Gallery';
import { ChildMeta } from '../meta';
import { getSrc } from 'gatsby-plugin-image';
import useUrlParams from '../../hooks/useUrlParams';
import { isDomAvailable, moderURL } from '../../utils/env-utils';
import Button, { ButtonLinkWrapper } from '../../components/common/button/Button';
import { LinkProps } from '../../hooks/useContentfulCallToAction';

type AccommodationProps = PageProps & {
  data: {
    accommodation: AccommodationFieldsRaw;
  };
  pageContext: PageContextTypes;
};

const contentKey = 'accommodations';

const Accommodation: React.FC<AccommodationProps> = ({
  data,
  pageContext,
  location,
}) => {
  const { locale } = pageContext;

  /*
  const params = React.useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  */
  const params = useUrlParams({ location });

  const guestsParams = React.useMemo(() => {
    const adultsParam: string | null = params['adults'] as string;
    const childrenParam: string | null = params['children'] as string;
    if (adultsParam && childrenParam) {
      return `&adult=${adultsParam}&child=${childrenParam}`;
    }
    return '';
  }, [params]);

  const datesParams = React.useMemo(() => {
    const startParam: string | null = params['start'] as string;
    const endParam: string | null = params['end'] as string;
    const startDate = startParam && new Date(+startParam);
    const endDate = endParam && new Date(+endParam);
    if (startDate && endDate) {
      const days = differenceInFullDays(startDate, endDate);
      return `&date=${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}&length=${days}`;
    } else {
      return '&length=2';
    }
  }, [params]);

  const {
    image,
    images,
    metaImage,
    accommodationId,
    title,
    description,
    mainContent,
    destinationCode,
    productCode,
    beds,
    extraBeds,
    accType,
    address,
    postCode,
    areaName,
    location: accLocation,
    ratingRaw,
    amenities: rawAmenities,
    fromModerAPI,
  } = data.accommodation;

  const iframeSrc = React.useMemo(() => {
    const urlParams = `dcode=${destinationCode}&pcode=${productCode}${guestsParams}${datesParams}&culture=${locale.toUpperCase()}&style=${
      process.env.GATSBY_HOST_URL
    }/css/reservation-form.css`;
    return `https://varaamo.levi.fi/shops/acc/site/searchengine.aspx?${urlParams}`;
  }, [productCode, datesParams, destinationCode, guestsParams, locale]);

  const tidy = React.useCallback((html) => {
    try {
      const d = document.createElement('div');
      d.innerHTML = html;
      return d.innerHTML;
    } catch (e) {
      return undefined;
    }
  }, []);

  const tidyContent = React.useMemo(
    () => tidy(mainContent),
    [mainContent, tidy],
  );

  const rooms = resolveRooms(rawAmenities);

  const meta = {
    title,
    lang: locale,
    description: description ?? '',
    image: metaImage && getSrc(metaImage?.childImageSharp?.image),
    hiddenFromSearchEngines: false, // or is it?  🤔
  };

  const roomsTag =
    rooms > 0 &&
    resolveTagByKey(
      String(rooms),
      contentKey,
      'capacity',
      rooms > 1 ? 'Rooms' : 'Room',
      accommodationCategories,
    );

  const bedsTag = resolveTagByKey(
    extraBeds !== '0' ? `${beds}+${extraBeds}` : beds,
    contentKey,
    'capacity',
    beds !== '1' ? 'Beds' : 'Bed',
    accommodationCategories,
  );

  const amenities = React.useMemo(() => {
    return resolveAmenities(rawAmenities, accType);
  }, [accType, rawAmenities]);

  const amenitiesTags = resolveTags(
    amenities,
    contentKey,
    accommodationCategories,
  );

  const tags = roomsTag
    ? [...amenitiesTags, bedsTag, roomsTag]
    : [...amenitiesTags, bedsTag];

  const amenitiesListCleaned = React.useMemo(() => {
    const excludeList = [
      'Rooms',
      'Distance',
      'Classification',
      'SquareMeter',
      'AdditionalSquareMeter',
      'BuiltIn',
      'Traveller',
      'Family',
      'Campaign',
    ];
    return rawAmenities.filter(
      (item) => item.Value === '1' && !excludeList.includes(item.Name),
    );
  }, [rawAmenities]);

  const distanceStr = React.useMemo(() => {
    const distance =
      rawAmenities &&
      rawAmenities.find((amenity: Amenity) => amenity.Name === 'Distance');
    if (!distance) {
      return '0,00';
    }
    return distance.Value.replace('.', ',');
  }, [rawAmenities]);

  const { isValid, isBelowMd } = useBreakpoints();
  return (
    <>
      <ChildMeta {...meta} />
      <main>
        {image?.childImageSharp && <MainHeader image={image.childImageSharp} />}
        <Section subPage>
          <SubpageGrid>
            <SubpageGridElement padded>
              {isValid && isBelowMd && images ? (
                <Gallery items={images} />
              ) : null}
              <TextContent leftAligned>
                <Styled.HeaderStyled>
                  <Typography.Display>{title}</Typography.Display>
                </Styled.HeaderStyled>
                <Styled.MetaStyled>
                  <Styled.AreaLabelStyled>
                    <Typography.BodySmall>{areaName}</Typography.BodySmall>
                  </Styled.AreaLabelStyled>
                  <StarRating rating={ratingRaw} />
                </Styled.MetaStyled>

                <Styled.Divider />

                <Styled.TagsWrapperStyled>
                  <CardTags tags={tags} />
                </Styled.TagsWrapperStyled>

                <Styled.Divider />

                {amenitiesListCleaned.length > 0 ? (
                  <Styled.AmenitiesListWrapper>
                    <Typography.SubHeadingLarge>
                      {localize('accommodations.amenities')}
                    </Typography.SubHeadingLarge>
                    <Styled.AmenitiesList role="list">
                      {amenitiesListCleaned.map((item) => {
                        return (
                          <Styled.AmenitiesListItem
                            key={item.Name}
                            role="listitem"
                          >
                            <Styled.AmenitiesListIcon aria-hidden="true">
                              <Icon type={Icons.Check} size={IconSize.Size16} />
                            </Styled.AmenitiesListIcon>

                            <Styled.AmenitiesListItemContent>
                              {localize(
                                `accommodations.categories.${item.Name}`,
                              )}
                            </Styled.AmenitiesListItemContent>
                          </Styled.AmenitiesListItem>
                        );
                      })}
                    </Styled.AmenitiesList>
                  </Styled.AmenitiesListWrapper>
                ) : null}

                <Styled.MainContentStyled>
                  <Typography.BodyIngres>{description}</Typography.BodyIngres>
                  {tidyContent && (
                    <Typography.BodyLarge
                      className="contentfulMarkdown"
                      dangerouslySetInnerHTML={{
                        __html: tidyContent,
                      }}
                    />
                  )}
                </Styled.MainContentStyled>
              </TextContent>
            </SubpageGridElement>
            <SubpageGridElement column="aside">
              {!isBelowMd && images && (
                <Styled.GalleryButtonWrapper>
                  <Gallery items={images} />
                </Styled.GalleryButtonWrapper>
              )}
              <Styled.IframeContainer>
                {fromModerAPI ? (
                  <ButtonLinkWrapper {...{target:"_blank", href:moderURL(accommodationId, locale), as:"a"} as LinkProps<"a">}>
                    <Button style={{width: "100%"}}>{localize('accommodations.cardLinkBookLabel')}</Button>
                  </ButtonLinkWrapper>
                ) : (
                  <Styled.IframeWrapper>
                    {isDomAvailable() && <Styled.Iframe src={iframeSrc} />}
                  </Styled.IframeWrapper>
                ) }

              { // VLD-560: Customer wanted a quick hack to show custom notice on these locations
                SpecialMessageAccomodationIds.find(id => id == accommodationId) && <>
                  <Typography.BodyLarge dangerouslySetInnerHTML={{__html: localize('accommodations.salesNotice')}}/>
                </> 
              }
              </Styled.IframeContainer>
            </SubpageGridElement>
          </SubpageGrid>
        </Section>
        {accLocation && (
          <Section>
            <Grid>
              <MapContainer
                title={localize('accommodations.location')}
                address={
                  `${address}, ${postCode} ${String.fromCharCode(
                    183,
                  )} ${areaName} ${String.fromCharCode(183)} ${localize(
                    'accommodations.distance',
                  )} ${distanceStr} km` || ''
                }
                location={accLocation}
              />
            </Grid>
          </Section>
        )}
      </main>
    </>
  );
};

export default Accommodation;

// VLD-560: Customer wanted a quick hack to show custom notice on these locations
const SpecialMessageAccomodationIds = [
  "HSOKOS-H2",    // Break Sokos Hotel Levi
  "HSITA-H20F",   // Lapland Hotels Sirkantähti
  "HSITA-H27F",   // Lapland Hotels Sirkantähti
  "HSITA-H35F",   // Lapland Hotels Sirkantähti
  "HSITA-H41F",   // Lapland Hotels Sirkantähti
  "HSITA-H42F",   // Lapland Hotels Sirkantähti
  "HSITA-H48F",   // Lapland Hotels Sirkantähti
  "HSITA-H52F",   // Lapland Hotels Sirkantähti
  "HSITA-HHT2",   // Lapland Hotels Sirkantähti
  "HSITA-HT5F",   // Lapland Hotels Sirkantähti
  "PTK5MF-C127",  // Hotel K5 Levi
  "PTK5MF-C192",  // Hotel K5 Levi
  "PTK5MF-C242",  // Hotel K5 Levi
  "PTK5MF-C281",  // Hotel K5 Levi
  "PTK5MF-C70",   // Hotel K5 Levi
  "HHPANOR-H1F",  // Hotel Levi Panorama
  "HHPANOR-H1SF", // Hotel Levi Panorama
  "HHPANOR-H2F",  // Hotel Levi Panorama
  "HHPANOR-H2SF", // Hotel Levi Panorama
  "HHPANOR-H3",   // Hotel Levi Panorama
  "HHPANOR-H4",   // Hotel Levi Panorama
  "HHPANOR-HJSF", // Hotel Levi Panorama
  "HHPANOR-JSSF", // Hotel Levi Panorama
  "HHPANOR-JSVF", // Hotel Levi Panorama
]

export const accommodationPageQuery = graphql`
  query AccommodationById($id: String!) {
    accommodation(id: { eq: $id }) {
      amenities {
        Name
        Value
      }
      accommodationId
      title
      description
      address
      postCode
      accType
      areaCode
      areaName
      destinationCode
      productCode
      mainContent
      beds
      extraBeds
      capacity {
        min
        max
      }
      ratingRaw
      rating
      location {
        lat
        lon
      }
      images
      image: cardImage {
        childImageSharp {
          image: gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      metaImage: cardImage {
        childImageSharp {
          image: gatsbyImageData(
            layout: FIXED
            width: 1200
            height: 630
            formats: [JPG]
            placeholder: NONE
          )
        }
      }
      fromModerAPI
    }
  }
`;
