import styled from 'styled-components';
import { AppColor } from '../../../styles/colors';
import FontRules from '../../../styles/fontRules';

const NumericInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${AppColor.Neutral700};
  position: relative;
  flex: 1;
`;

const NumericInput = styled.div`
  ${FontRules.TradeGothicNext.Regular}
  color: inherit;
  border-radius: 100%;
  border: 1px solid ${AppColor.Neutral300};
  width: 2.2rem;
  height: 2.2rem;
  line-height: 2rem;
  text-align: center;
  padding: 0;
`;

const NumericInputButton = styled.button`
  line-height: 2rem;
  color: inherit;
  font-size: 20px;
  padding: 0;
  margin: 0 0.8rem;
  cursor: pointer;
  height: 2rem;
  width: auto;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100px;
`;

export default {
  NumericInputContainer,
  NumericInput,
  Wrapper,
  NumericInputButton,
};
