import React from 'react';
import useBreakpoints from '../../hooks/useBreakpoints';
import { localize } from '../../localization/i18n';
import { Theme } from '../../styles/themes';
import { Icons } from '../../types/icon-types';
import { DateRange, isValidDateRange } from '../../utils/date-utils';
import Icon, { IconSize } from '../common/icon/Icon';
import AvailabilitySearchForm from '../common/list-filters/AvailabilitySearchForm';
import FilterGuestsForm, {
  Guests,
} from '../common/list-filters/FilterGuestsForm';
import { Typography } from '../common/typography/Typography.styled';
import Styled from './SearchBar.styled';

type AccommodationSearchBarProps = {
  onGuestsChange: (guests: Guests) => void;
  guests: Guests;
  dates: DateRange | undefined;
  onDatesChange: (range: DateRange | undefined) => void;
  onSubmit: () => void;
};

const AccommodationSearchBar: React.FC<AccommodationSearchBarProps> = ({
  onGuestsChange,
  guests,
  dates,
  onDatesChange,
  onSubmit,
}): JSX.Element => {
  const { isPhone } = useBreakpoints();

  return (
    <Styled.SearchBar theme={Theme.Primary}>
      <Styled.FiltersContainer>
        <AvailabilitySearchForm
          selectedRange={dates as Required<DateRange> | undefined}
          onSelectedDateRangeChange={(range) =>
            range && isValidDateRange(range)
              ? onDatesChange(range)
              : onDatesChange(undefined)
          }
        />

        <Styled.VerticalDivider />

        <FilterGuestsForm
          onChange={(guests) => onGuestsChange(guests)}
          guests={guests}
        />
      </Styled.FiltersContainer>

      {!isPhone ? (
        <Styled.SubmitButton
          onClick={onSubmit}
          aria-label={localize('accommodations.findAccommodations')}
        >
          <Icon type={Icons.SearchLarge} size={IconSize.Size24} />
        </Styled.SubmitButton>
      ) : (
        <Styled.SearchButton onClick={onSubmit}>
          <Icon type={Icons.SearchLarge} size={IconSize.Size24} />
          <Typography.Button>
            {localize('accommodations.findAccommodations')}
          </Typography.Button>
        </Styled.SearchButton>
      )}
    </Styled.SearchBar>
  );
};

export default AccommodationSearchBar;
