import React from 'react';
import { Icons } from '../../../types/icon-types';
import Icon from '../../common/icon/Icon';
import { Typography } from '../../common/typography/Typography.styled';
import Styled from './BackNavigation.styled';
import { navigate } from 'gatsby';
import { PageContextTypes } from '../../../contentful/templates/types';
import { HistoryLocation } from '@reach/router';
import { localize } from '../../../localization/i18n';

type BackNavigationProps = Pick<PageContextTypes, 'breadcrumbs'> & {
  location: HistoryLocation;
};

const BackNavigation = ({
  breadcrumbs,
  location,
}: BackNavigationProps): JSX.Element => {
  const backLinkTarget = React.useMemo(
    () =>
      location && location.state && location.state.referrer
        ? location.state.referrer
        : breadcrumbs && breadcrumbs.length > 1
        ? breadcrumbs[breadcrumbs.length - 2].to
        : -1,
    [breadcrumbs, location],
  );

  const onClick = React.useCallback(
    () => navigate(backLinkTarget),
    [backLinkTarget],
  );

  return (
    <Styled.BackNavigationContainer>
      <Styled.BackNavigationContainerInner>
        <Styled.BackNavigation>
          <Styled.BackButton onClick={onClick}>
            <Icon type={Icons.ArrowLeftLarge} />
            <Typography.LabelBig>
              {localize('common.goBack')}
            </Typography.LabelBig>
          </Styled.BackButton>
        </Styled.BackNavigation>
      </Styled.BackNavigationContainerInner>
    </Styled.BackNavigationContainer>
  );
};

export default BackNavigation;
