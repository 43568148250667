// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-contentful-templates-accommodation-page-tsx": () => import("./../../../src/contentful/templates/accommodationPage.tsx" /* webpackChunkName: "component---src-contentful-templates-accommodation-page-tsx" */),
  "component---src-contentful-templates-activity-page-tsx": () => import("./../../../src/contentful/templates/activityPage.tsx" /* webpackChunkName: "component---src-contentful-templates-activity-page-tsx" */),
  "component---src-contentful-templates-contentful-accommodation-page-tsx": () => import("./../../../src/contentful/templates/contentfulAccommodationPage.tsx" /* webpackChunkName: "component---src-contentful-templates-contentful-accommodation-page-tsx" */),
  "component---src-contentful-templates-contentful-blog-post-page-tsx": () => import("./../../../src/contentful/templates/contentfulBlogPostPage.tsx" /* webpackChunkName: "component---src-contentful-templates-contentful-blog-post-page-tsx" */),
  "component---src-contentful-templates-contentful-eat-and-drink-page-tsx": () => import("./../../../src/contentful/templates/contentfulEatAndDrinkPage.tsx" /* webpackChunkName: "component---src-contentful-templates-contentful-eat-and-drink-page-tsx" */),
  "component---src-contentful-templates-contentful-page-tsx": () => import("./../../../src/contentful/templates/contentfulPage.tsx" /* webpackChunkName: "component---src-contentful-templates-contentful-page-tsx" */),
  "component---src-contentful-templates-event-pages-event-page-tsx": () => import("./../../../src/contentful/templates/eventPages/eventPage.tsx" /* webpackChunkName: "component---src-contentful-templates-event-pages-event-page-tsx" */),
  "component---src-contentful-templates-event-pages-events-page-tsx": () => import("./../../../src/contentful/templates/eventPages/eventsPage.tsx" /* webpackChunkName: "component---src-contentful-templates-event-pages-events-page-tsx" */),
  "component---src-contentful-templates-service-list-pages-contentful-search-rentals-page-tsx": () => import("./../../../src/contentful/templates/serviceListPages/contentfulSearchRentalsPage.tsx" /* webpackChunkName: "component---src-contentful-templates-service-list-pages-contentful-search-rentals-page-tsx" */),
  "component---src-contentful-templates-service-list-pages-contentful-search-restaurants-page-tsx": () => import("./../../../src/contentful/templates/serviceListPages/contentfulSearchRestaurantsPage.tsx" /* webpackChunkName: "component---src-contentful-templates-service-list-pages-contentful-search-restaurants-page-tsx" */),
  "component---src-contentful-templates-service-list-pages-contentful-search-services-page-tsx": () => import("./../../../src/contentful/templates/serviceListPages/contentfulSearchServicesPage.tsx" /* webpackChunkName: "component---src-contentful-templates-service-list-pages-contentful-search-services-page-tsx" */),
  "component---src-contentful-templates-service-list-pages-search-accommodations-page-tsx": () => import("./../../../src/contentful/templates/serviceListPages/searchAccommodationsPage.tsx" /* webpackChunkName: "component---src-contentful-templates-service-list-pages-search-accommodations-page-tsx" */),
  "component---src-contentful-templates-service-list-pages-search-activities-page-tsx": () => import("./../../../src/contentful/templates/serviceListPages/searchActivitiesPage.tsx" /* webpackChunkName: "component---src-contentful-templates-service-list-pages-search-activities-page-tsx" */),
  "component---src-contentful-templates-service-pages-contentful-rental-page-tsx": () => import("./../../../src/contentful/templates/servicePages/contentfulRentalPage.tsx" /* webpackChunkName: "component---src-contentful-templates-service-pages-contentful-rental-page-tsx" */),
  "component---src-contentful-templates-service-pages-contentful-restaurant-page-tsx": () => import("./../../../src/contentful/templates/servicePages/contentfulRestaurantPage.tsx" /* webpackChunkName: "component---src-contentful-templates-service-pages-contentful-restaurant-page-tsx" */),
  "component---src-contentful-templates-service-pages-contentful-service-page-tsx": () => import("./../../../src/contentful/templates/servicePages/contentfulServicePage.tsx" /* webpackChunkName: "component---src-contentful-templates-service-pages-contentful-service-page-tsx" */),
  "component---src-contentful-templates-weather-pages-weather-cameras-page-tsx": () => import("./../../../src/contentful/templates/weatherPages/weatherCamerasPage.tsx" /* webpackChunkName: "component---src-contentful-templates-weather-pages-weather-cameras-page-tsx" */),
  "component---src-contentful-templates-weather-pages-weather-page-tsx": () => import("./../../../src/contentful/templates/weatherPages/weatherPage.tsx" /* webpackChunkName: "component---src-contentful-templates-weather-pages-weather-page-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-en-search-tsx": () => import("./../../../src/pages/en/search.tsx" /* webpackChunkName: "component---src-pages-en-search-tsx" */),
  "component---src-pages-haku-tsx": () => import("./../../../src/pages/haku.tsx" /* webpackChunkName: "component---src-pages-haku-tsx" */),
  "component---src-pages-navigation-widget-tsx": () => import("./../../../src/pages/navigationWidget.tsx" /* webpackChunkName: "component---src-pages-navigation-widget-tsx" */)
}

