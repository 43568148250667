import styled from 'styled-components';
import { AppColor } from '../../../styles/colors';
import { hover } from '../../../styles/utils';
import { Typography } from '../typography/Typography.styled';

type CheckboxProps = {
  checked?: boolean;
};

const Checkbox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: calc(0.5rem - 1px) 0.2rem calc(0.5rem - 1px) 0.2rem;

  max-height: 9rem;
  overflow-y: auto;
  transform: translate(var(--transform-x, 0), 0);

  border-radius: 0.5rem;
`;

const CheckboxInput = styled(Typography.LabelSmall)<CheckboxProps>`
  white-space: nowrap;
  line-height: 1;
  color: ${({ checked }) =>
    !checked ? AppColor.Neutral700 : AppColor.LeviBlue};

  ${hover} {
    cursor: pointer;
  }
`;

const CheckboxLabel = styled(CheckboxInput).attrs({ as: 'label' })`
  color: ${AppColor.LeviBlue};
  display: flex;
  align-items: center;
  gap: 0.25rem;

  input[type='checkbox'] {
    appearance: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white; 
    background-color: white; 

    &:before {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 3px;
      border: 1px solid ${AppColor.LeviBlue};
      color: white !important;
    }

    &:checked:before {
      background: ${AppColor.LeviBlue};
    }

    &:checked:after {
      content: '';
      display: block;
      width: 0.325rem;
      height: 0.675rem;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform-origin: 50% 50%;
      transform: rotate(45deg);
      position: absolute;
      margin-top: -2px;
    }
  }
`;
export default {
  CheckboxInput,  
  CheckboxLabel,
  Checkbox,
};
